/*
 * IMPORTS
 */
import Dynamic from 'next/dynamic' // NPM: Next.js dynamic component utility.
import PropTypes from 'prop-types' // NPM: react prop validation library.


/*
 * STYLES
 */
import ContainerWrapper from './index.style'


/*
 * COMPONENTS
 */
const Container = Dynamic(() => import('reusecore/src/elements/Container'))
const Text = Dynamic(() => import('reusecore/src/elements/Text'))


/*
 * OBJECTS
 */
const Index = ({ statusCode }) => {
  // Const assignment.
  const _errorDescription = {
    '404': "Looks like the page you're trying to visit doesn't exist or something is not right. Please check the URL."
  }

  // Return component.
  return (
    <Container noGutter width={1200}>
      <ContainerWrapper>
        <h1 className='heading topToBottom position unset'>{statusCode.toString().split('')[0]}<span className='font white'>{statusCode.toString().split('')[1]}</span>{statusCode.toString().split('')[2]}</h1>
        <Text
          className='description font white'
          content={_errorDescription[statusCode] ?? 'Oh No! Something went wrong. Please go back or try again later on same thing. Sorry for inconvenience.'}
        />
      </ContainerWrapper>
    </Container>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'statusCode': PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}
Index.defaultProps = {
  'statusCode': '404'
}


/*
 * EXPORTS
 */
export default Index
