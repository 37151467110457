/*
 * IMPORTS
 */
import Dynamic from 'next/dynamic' // NPM: Next.js dynamic component utility.
import Styled from 'styled-components' // NPM: React.js Styled library.


/*
 * COMPONENTS
 */
const Box = Dynamic(() => import('reusecore/src/elements/Box'))


/*
 * OBJECTS
 */
const ContainerWrapper = Styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1;

  & > .description {
    text-align: center;
    margin: auto;
  }

  & > .heading {
    margin-top: 120px !important;
    font-size: 320px !important;
    height: initial !important;
    text-align: center;
    font-weight: 900 !important;
    @media only screen and (max-width: 624px) {
      font-size: 100px !important;
    }
  }
`


/*
 * EXPORTS
 */
export default ContainerWrapper
