/*
 * IMPORTS
 */
import Dynamic from 'next/dynamic' // NPM: Next.js dynamic component utility.


/*
 * PACKAGES
 */
import ErrorComponent from '~packages/common/container/Error'


/*
 * COMPONENTS
 */
const Navbar = Dynamic(() => import('~packages/common/container/Navbar'), { 'loading': Object.Next.Dynamic.Loading })
const Footer = Dynamic(() => import('~packages/common/container/Footer'), { 'loading': Object.Next.Dynamic.Loading })
const GlobalFooter = Dynamic(() => import('~packages/common/container/GlobalFooter'), { 'loading': Object.Next.Dynamic.Loading })
const GetInTouch = Dynamic(() => import('~packages/common/container/GetInTouch'), { 'loading': Object.Next.Dynamic.Loading })


/*
 * OBJECT
 */
const Index = ({ container, statusCode }) =>
  // Return component.
  (
    <section style={container}>
      <div className='gradientWrapper topRight round'>
        <canvas id='ErrorGradient' className='gradient color blackAndPrimary' />
        <div className='overlay occlusion' />
        <Navbar />
        <ErrorComponent statusCode={statusCode} />
      </div>
      <GetInTouch />
      <Footer />
      <GlobalFooter />
    </section>
  )



/*
 * PROPTYPES
 */
Index.defaultProps = {
  'container': {
    'overflowX': 'hidden'
  }
}


/*
 * EXPORTS
 */
export default Index
